/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import type { Invoice_Api_DTO_InvoiceDocument } from '../models/Invoice_Api_DTO_InvoiceDocument';
import { BaseHttpRequest } from '../core/BaseHttpRequest';
@Injectable({
    providedIn: 'root',
})
export class InvoiceCreationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Endpoint to create v2 invoice
     * Endpoint to create a v2 invoice from UI into the core tables. This endpoint will be deprecated when we have fully migrated to v2 invoices in services
     * @returns string Success
     * @throws ApiError
     */
    public postApiV21InvoicesCreate({
        requestBody,
    }: {
        requestBody?: Invoice_Api_DTO_InvoiceDocument,
    }): Observable<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v2.1/invoices/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict`,
                423: `Client Error`,
                500: `Server Error`,
            },
        });
    }
}
